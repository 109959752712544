import axios from "../api/axios";
import useSecuredApiService from "./useSecuredApiService";

const useEndpoints = () => {
  const securedApi = useSecuredApiService();

  const login = async (email, password) => {
    const payload = {
      email,
      password,
    };

    try {
      const result = await axios.post("auth/login", payload, {
        headers: {
          "Content-Type": "application/json",
        }
      });

      return result.data;
    } catch (err) {
      throw err;
    }
  };

  const createPost = async (post) => {
    try {
      const result = await securedApi.post("posts/create", post);
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  const uploadPhotos = async (postId, files) => {
    try {
      const filesData = new FormData();

      for (let i = 0; i < files.length; i++) {
        filesData.append("files", files[i]);
      }

      await securedApi.put(`posts/${postId}/upload-photos`, filesData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (err) {
      throw err;
    }
  };

  const getLocationDataForInput = async (location) => {
    try {
      const response = await securedApi.get(`places/get?input=${location}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  const getPostDetails = async (postId) => {
    try {
      const response = await securedApi.get(`posts/${postId}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  const getPostsFromPendingPool = async () => {
    try {
      const response = await securedApi.get(`pending-pool/all`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  const changePostStatus = async (type, changeRequest) => {
    try {
      await securedApi.post(
        `pending-pool/change-approval-status/${type}`,
        changeRequest
      );
    } catch (err) {
      throw err;
    }
  };

  return {
    login,
    createPost,
    uploadPhotos,
    getLocationDataForInput,
    getPostDetails,
    getPostsFromPendingPool,
    changePostStatus,
  };
};

export default useEndpoints;
