import ls from "localstorage-slim";
import { useEffect } from "react";
import { axiosSecured } from "../api/axios";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

const useSecuredApiService = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosSecured.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.jwt}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosSecured.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (
          (error?.response?.status === 401 ||
            error?.response?.status === 403) &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          const { jwt, refreshToken } = await refresh(auth?.refreshToken);
          prevRequest.headers["Authorization"] = `Bearer ${jwt}`;

          // store the refreshed tokens
          ls.set("jwt", jwt, { ttl: 3600, encrypt: true });
          ls.set("refreshToken", refreshToken, {
            ttl: 60 * 60 * 24,
            encrypt: true,
          });

          return axiosSecured(prevRequest);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosSecured.interceptors.request.eject(requestIntercept);
      axiosSecured.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosSecured;
};

export default useSecuredApiService;
