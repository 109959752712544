import { UserOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import ls from 'localstorage-slim';
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import { _STYLES } from "../utils/customStyles";

const { navBarWrap, w100 } = _STYLES;
const useStyles = createUseStyles({
  navBarWrap,
  w100,
});
const NavMenu = () => {
  const classes = useStyles();

  const { setAuth } = useContext(AuthContext);

  const navigate = useNavigate();

  const signout = () => {
    ls.remove('jwt');
    setAuth({});
    navigate("/login");
  };

  return (
    <nav className={classes.navBarWrap}>
      <Row className={classes.w100} justify={"end"}>
        <Col>
          <Button icon={<UserOutlined />} type="default" onClick={signout}>
            Sign out
          </Button>
        </Col>
      </Row>
    </nav>
  );
};

export default NavMenu;
