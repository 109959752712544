import { Layout, Menu, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { createUseStyles } from "react-jss";
import { Outlet, useNavigate } from "react-router-dom";
import NavMenu from "./components/common/navMenu";
import { _STYLES } from "./components/utils/customStyles";

const sidebarItems = [
  {
    label: "Posts",
    key: "/posts",
    children: [
      {
        label: "Create",
        key: "/create-post",
      },
      {
        label: "Pending Pool",
        key: "/pending-pool",
      },
    ],
  },
  {
    label: "Create User",
    key: "/create-user",
  },
];
const { bodyInnerWrap } = _STYLES;
const useStyles = createUseStyles({
  bodyInnerWrap,
});

const Home = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { colorBgContainer, borderRadiusLG } = theme.useToken();

  const handleMenuItem = ({ key }) => {
    if (key) {
      navigate(key);
    }
  };

  return (
    <>
      <NavMenu />
      <Layout className={classes.bodyInnerWrap}>
        <Sider
          collapsible
          width={200}
          style={{ backgroundColor: colorBgContainer }}
        >
          <Menu
            mode="inline"
            items={sidebarItems}
            onClick={handleMenuItem}
            style={{ height: "100%", borderRight: 0 }}
          />
        </Sider>

        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <div
              style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Home;
