import { DeviceType } from "./constants";

export const detectDeviceType = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth < 600) {
    return DeviceType.mobile;
  } else if (screenWidth < 992) {
    return DeviceType.tablet;
  } else {
    return DeviceType.pc;
  }
};