import { Button, Card, Carousel, Col, Row, Space } from "antd";
import { createUseStyles } from "react-jss";
import { useMetaContext } from "./core/contexts/MetaContextProvider";
import { DeviceType } from "./utils/constants";
import { _STYLES } from "./utils/customStyles";
import Image from "./utils/image";

const { textMd2, textMd1, textCenter, px1, colorSubText, w100 } = _STYLES;
const useStyles = createUseStyles({
  textMd2,
  textMd1,
  textCenter,
  px1,
  colorSubText,
  w100,
});

const PostCard = (props) => {
  const {
    pendingPost: { post },
    onViewClicked,
  } = props;
  const classes = useStyles();

  const { deviceType } = useMetaContext();

  const isMobile = deviceType === DeviceType.mobile;

  return (
    <Col span={24} className={isMobile ? classes.px1 : ""}>
      <Card
        cover={
          post.thumbnailUrls.length === 0 ? (
            <Image />
          ) : (
            <Carousel autoplaySpeed={4000} draggable autoplay>
              {post.thumbnailUrls.map((item, key) => (
                <Image
                  key={key}
                  src={item}
                  isBgPlaceholder
                  alt={`post-${key}`}
                />
              ))}
            </Carousel>
          )
        }
      >
        <Space
          className={classes.textCenter + " " + classes.w100}
          direction="vertical"
          size={[0, 24]}
        >
          <Space direction="vertical" size={[0, 4]}>
            <h5 className={classes.textMd2}>{post.location}</h5>
            <span className={classes.colorSubText + " " + classes.textMd1}>
              {post.howLongAgo}
            </span>
          </Space>
          <Row>
            <Button block type="default" onClick={() => onViewClicked(post)}>
              View
            </Button>
          </Row>
        </Space>
      </Card>
    </Col>
  );
};

export default PostCard;
