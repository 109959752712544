import { Button, Col, Empty, Flex, Input, Modal, Row, Space, Spin } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import useEndpoints from "../../hooks/useEndpoints.js";
import { CustomContainer } from "../common/components/container.jsx";
import { useMetaContext } from "../core/contexts/MetaContextProvider.jsx";
import PostCard from "../post-card.jsx";
import { DeviceType } from "../utils/constants.js";
import { _STYLES } from "../utils/customStyles.js";
import PostDetailsModal from "./PostDetailsModal.jsx";

const {
  textMd2,
  textMd1,
  paraLine,
  colorPrimary,
  textCenter,
  blogCard,
  px1,
  colorSubText,
  bgImgPlaceholder,
  w100,
  imgPlaceholder,
  py1,
} = _STYLES;

const useStyles = createUseStyles({
  textMd2,
  textMd1,
  paraLine,
  colorPrimary,
  textCenter,
  blogCard,
  px1,
  colorSubText,
  bgImgPlaceholder,
  w100,
  imgPlaceholder,
  py1,
});

const PendingPool = () => {
  const { deviceType } = useMetaContext();

  const rejectionInputRef = useRef();

  const isMobile = deviceType === DeviceType.mobile;

  const { getPostsFromPendingPool, changePostStatus } = useEndpoints();

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(true);
  const [posts, setPosts] = useState([]);

  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const classes = useStyles();

  const { TextArea } = Input;

  const togglePostDetailsModal = useCallback((open = false) => {
    setIsPostModalOpen((isOpen) => open || !isOpen);
  }, []);

  const handleViewClick = useCallback(
    (post) => {
      setSelectedPost(post);
      togglePostDetailsModal();
    },
    [togglePostDetailsModal]
  );

  const handleApproval = useCallback(
    async ({ id, createdById }) => {
      const poolId = posts.find((pp) => pp.post.id === id).poolId;
      const request = {
        poolId,
        postId: id,
        userId: createdById,
      };

      try {
        await changePostStatus("approved", request);
        togglePostDetailsModal(false);
        setLoading(true);
      } catch (err) {
        throw err;
      }
    },
    [changePostStatus, posts, togglePostDetailsModal]
  );

  const handleRejection = useCallback(
    (confirm = false) =>
      (post) => {
        if (!confirm) {
          Modal.confirm({
            icon: null,
            width: 500,
            maskClosable: false,
            title: "Confirm Rejection",
            onOk: () => handleRejection(true)(post),
            content: (
              <Col className={classes.py1}>
                <TextArea
                  ref={rejectionInputRef}
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  size="large"
                  placeholder="Reason for Rejection"
                  allowClear
                />
              </Col>
            ),
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            ),
          });
        } else {
          console.log(
            post,
            rejectionInputRef.current?.resizableTextArea?.textArea?.value
          );
        }
      },
    [classes.py1]
  );

  const postsRender = useMemo(() => {
    return posts.length > 0 ? (
      <Row
        gutter={[
          { xs: 8, sm: 16, md: 24 },
          { xs: 8, sm: 16, md: 24 },
        ]}
        className={classes.textCenter}
        align={"top"}
      >
        {posts.map((post, idx) => (
          <Col key={idx} xs={24} sm={12} md={8}>
            <PostCard pendingPost={post} onViewClicked={handleViewClick} />
          </Col>
        ))}
      </Row>
    ) : (
      <Flex justify="center" align="center" gap="middle">
        <Empty />
      </Flex>
    );
  }, [classes.textCenter, handleViewClick, posts]);

  useEffect(() => {
    const getPendingPosts = async () => {
      try {
        const pendingPosts = await getPostsFromPendingPool();
        setPosts(pendingPosts);
      } catch (err) {
        setSuccess(false);
        throw err;
      } finally {
        setLoading(false);
      }
    };

    getPendingPosts();
  }, []);

  return (
    <Col>
      <CustomContainer padTop="lg" padBottom="lg">
        <Row
          justify={"center"}
          gutter={[
            { sm: 0, md: 24 },
            { xs: 24, sm: 48, md: 48 },
          ]}
        >
          <Col span={isMobile ? 24 : 18}>
            <Space
              className={classes.w100}
              direction="vertical"
              size={isMobile ? 16 : 40}
            >
              {loading ? (
                <Flex justify="center" align="center" gap="middle">
                  <Spin size="large" />
                </Flex>
              ) : success ? (
                <Col span={24}>{postsRender}</Col>
              ) : (
                <Empty
                  imageStyle={{ height: 250 }}
                  image="/images/error.png"
                  description={
                    <Space direction="vertical">
                      <p
                        className={classes.paraLine + " " + classes.colorError}
                      >
                        An error occured while fetching data.
                      </p>
                      <Button type="primary">Retry</Button>
                    </Space>
                  }
                ></Empty>
              )}
            </Space>
          </Col>
        </Row>
      </CustomContainer>
      <PostDetailsModal
        toggleModal={togglePostDetailsModal}
        isModalOpen={isPostModalOpen}
        post={selectedPost}
        handleApproval={handleApproval}
        handleRejection={handleRejection(false)}
      />
    </Col>
  );
};

export default PendingPool;
