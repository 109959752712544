export const _THEME = {
    color: {
      primary: {
        main: "#75c6fe",
        accent1: "#f0f8ff",
      },
      accent: {
        main: "#333",
        accent1: "#b08000",
      },
      grey: {
        accent1: "#f1f1f1",
        accent2: "#e2e2e2",
        accent3: "#333333",
        accent4: "#555",
        accent5: "#919191",
      },
      text: {
        main: "#1d1d1b",
        accent1: "#8f8f8f",
      },
      disabled: "#e1dfe9",
      black: "#000000",
      white: "#fff",
      green: "#3cb371",
      error: "#d73232",
    },
    size: {
      sm: {
        1: "0.7rem",
        2: "0.8rem",
        3: "0.9rem",
      },
      md: {
        1: "1rem",
        2: "1.25rem",
        3: "1.5rem",
      },
      lg: {
        1: "2rem",
        2: "3rem",
        3: "4rem",
      },
    },
    shadow: {
      1: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
      2: "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
      3: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
      4: "0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3)",
      5: "0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
      6: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2)",
      7: "0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2)",
      8: "0 22px 43px -20px rgba(0, 0, 0, 0.15)",
      9: "0 12px 23px -15px rgba(0, 0, 0, 0.15)",
    },
    spacing: (val) => 8 * val,
  };
  