import axios from "axios";

const HOST_NAME = process.env.REACT_APP_API_HOST_NAME;

export default axios.create({
  baseURL: `${HOST_NAME}/api`,
});

export const axiosSecured = axios.create({
  baseURL: `${HOST_NAME}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});
