import { createUseStyles } from "react-jss";

const globalStyle = createUseStyles({
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    "h1, h2, h3, h4, h5": {
      marginBottom: 0,
    },

    "body,body *:not([class*='customFont']):not([class*='customFont2']):not([class*='customFont'] *),button":
      {
        fontFamily: "Lexend, sans-serif !important",
      },

    img: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    ".ant-form-item-label>label": {
      fontSize: "1rem !important",
    },
    ".ant-form-item": {
      marginBottom: "32px",
    },
    p: {
      marginBottom: 0,
    },
    ".ant-btn-primary": {
      boxShadow: "0 2px 0 rgba(5,145,255,.1)",
    },
    ".ant-btn-block": {
      paddingRight: "11px !important",
    },
    ".ant-btn-lg": {
      paddingRight: "16px !important",
    },
    "p, label": {
      fontWeight: 300,
    },
    ".ant-input-affix-wrapper-focused": {
      boxShadow: "0 0 0 2px rgba(5,145,255,.1)",
    },
    'input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button':
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
  },
});

export default globalStyle;
