import React, { useContext } from "react";
import { useNotification } from "../../common/hooks/useNotification";

const NotificationContext = React.createContext({
  notify: undefined,
});

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider = ({ children }) => {
  const { notify, contextHolder } = useNotification();

  return (
    <NotificationContext.Provider
      value={{
        notify,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
