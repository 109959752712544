import { Outlet, Route, Routes } from "react-router-dom";
import Unauthorized from "./components/auth/Unauthorized";
import Layout from "./components/common/layout";
import Login from "./components/login/login";
import NotFound from "./components/NotFound";
import CreatePost from "./components/post/CreatePost";
import PostApproval from "./components/post/PendingPool";
import RequireAuth from "./components/RequireAuth";
import Home from "./home";

function App() {
  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Outlet />
            </div>
          }
        >
          {/* public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          {/* protected routes */}
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />}>
              <Route path="create-post" element={<CreatePost />} />
              <Route path="pending-pool" element={<PostApproval />} />
            </Route>
          </Route>

          {/* catch all */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Layout>
  );
}

export default App;
